<template>
  <div class="choose-location">
    <CSSelect height="40px" i-width="36px">
      <input v-model="locationName" :class="{unselectable:isEdit}" :disabled="isEdit" class="readonly-input"
             placeholder="请选择" readonly
             type="text"
             @click.stop="locationPanelVisible = true"
      >
    </CSSelect>
    <div v-if="locationPanelVisible" class="location-panel" @click.stop>
      <div class="location-search">
        <input v-model="locationKeyWord" placeholder="搜索位置" type="text">
        <button class="btn btn-primary font" @click="getLocationList(locationKeyWord)">查询</button>
      </div>
      <div class="tip">注：如果没有可用的位置，请联系运营部负责人添加。</div>
      <div v-if="locationList.length > 0" class="location-result">
        <p v-for="(item, index) in locationList" :key="index"
           @click="changeLocation(item)"
        >
          {{ `${item.buildingName || ''}${item.floor ? item.floor + '层' : ''}${item.specificLocation}` }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import {querySimilarLocationUrl} from "@/requestUrl";

export default {
  name: "ChooseMeterLocation",
  components: {
    CSSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      locationList: [],
      locationPanelVisible: false,
      timer: null,
      locationName: '',
      locationKeyWord: '',
    }
  },
  mounted() {
    //  console.log(this.isEdit);
    document.getElementsByTagName('body')[0].addEventListener('click', () => {
      this.locationPanelVisible = false;
    })
    document.getElementsByClassName('cs-dialog')[0].addEventListener('click', () => {
      this.locationPanelVisible = false;
    })
    this.getLocationList();
  },
  methods: {
    /**
     * 获取位置列表
     * @param {String} specificLocation 相似位置
     * */
    getLocationList(specificLocation = '') {
      let type = null;
      this.$fly.post(querySimilarLocationUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        specificLocation,
        type,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            // let result=res.data.filter((item)=>{
            //     return item.buildingName && item.floor && item.specificLocation
            // })
            this.locationList = res.data
          })
    },
    /**
     * 搜索位置
     * @param {Object} e 事件对象
     * */
    searchLocation(e) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getLocationList(e.target.value);
      }, 500);
    },
    /**
     * 修改选中的位置
     * @param {Object} location 位置
     * */
    changeLocation(location) {
      this.locationPanelVisible = false;
      this.locationName = `${location.buildingName || ''}${location.floor ? location.floor + '层' : ''}${location.specificLocation}`;
      this.$emit('changeLocation', location);
    }
  }
}
</script>

<style lang="stylus" scoped>
.choose-location
  position relative
  line-height 1
  font-size 20px

  .readonly-input
    width var(--readonly-input-width, 360px)
    height 40px
    border-radius 4px
    //border 1px solid #999
    padding 0 10px
    border unset
    font-size 24px
    outline none

  .location-panel
    position absolute
    background #fff
    z-index 9
    top 50px
    left 0
    border-radius 10px
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    width var(--location-panel-width, 400px)
    padding 10px

    .location-search
      width 100%

      input[type="text"]
        border-radius 4px
        border 1px solid #999
        padding 0 10px
        vertical-align middle
        width 300px
        height 40px
        box-sizing border-box

      button
        vertical-align middle
        float right
        height 40px
        padding 0
        width 60px
        line-height 40px
        box-shadow 0 5px 10px 0 rgba(0, 182, 148, 0.3)

    .tip
      color #999
      font-size 20px
      margin 6px 0
      line-height 1.4

    .tips
      color #999
      font-size 20px
      margin-top 10px

    .location-result
      max-height 400px
      overflow-y auto
      padding-bottom 10px

      p
        cursor pointer
        margin-bottom 0
        height 44.5px
        line-height 44.5px

        white-space nowrap
        overflow hidden
        text-overflow ellipsis

        &:not(:last-of-type)
          border-bottom 1px solid #f0f0f0

.font
  font-size 20px
  line-height: 30px !important

.unselectable
  background: #F0F0F0;
  color black
</style>
