<template>
    <CSDialog
            class="tenant-detail-dialog"
            dialog-title="添加监控"
            dialog-width="670px"
            :dialog-visible="addCameraVisible"
            @onClose="addCameraVisible = false"
            @onConfirm="addCamera"
    >
        <template
                v-slot:dialog-content
        >
            <div class="dialog-form">
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">第三方监控</div>
                    <div>
                        <CSSelect iWidth="36px" height="40px">
                            <select v-model="newCameraInfo.brand" style="width:385px;color: #999999">
                                <option value="">&nbsp;请选择</option>
                                <option
                                        v-for="camera in cameraModelList"
                                        :key="camera.id"
                                        :value="camera.id"
                                >
                                    &nbsp;{{ camera.name }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">型号</div>
                    <div>
                        <input
                                type="text"
                                style="
                    width:397px;
                    height:40px;
                    padding:0px 10px;
                    border:1px solid #979797;
                    border-radius:4px;
                "
                                placeholder="请输入"
                                v-model="newCameraInfo.type"
                        />
                    </div>
                </div>
                <div class="dialog-form-item" v-if="newCameraInfo.brand === 2">
                    <span class="dialog-form-item-label">事件类型</span>
                    <div>
                        <CSRadio :items="cameraEventType" v-model="newCameraInfo.eventType"/>
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">序列号</div>
                    <div>
                        <input
                                type="text"
                                style="
                    width:397px;
                    height:40px;
                    padding:0px 10px;
                    border:1px solid #979797;
                    border-radius:4px;
                "
                                placeholder="请输入"
                                v-model="newCameraInfo.sn"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">用户名</div>
                    <div>
                        <input
                                type="text"
                                style="
                            width:397px;
                            height:40px;
                            padding:0px 10px;
                            border:1px solid #979797;
                            border-radius:4px;
                        "
                                placeholder="请输入"
                                v-model="newCameraInfo.user"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">密码</div>
                    <div>
                        <input
                                type="text"
                                style="
                            width:397px;
                            height:40px;
                            padding:0px 10px;
                            border:1px solid #979797;
                            border-radius:4px;
                        "
                                placeholder="请输入"
                                v-model="newCameraInfo.password"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">内网IP</div>
                    <div>
                        <input
                                type="text"
                                style="
                            width:397px;
                            height:40px;
                            padding:0px 10px;
                            border:1px solid #979797;
                            border-radius:4px;
                        "
                                placeholder="请输入"
                                v-model="newCameraInfo.privateIp"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">外网IP</div>
                    <div>
                        <input
                                type="text"
                                style="
                            width:397px;
                            height:40px;
                            padding:0px 10px;
                            border:1px solid #979797;
                            border-radius:4px;
                        "
                                placeholder="请输入"
                                v-model="newCameraInfo.publicIp"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">外部TCP端口</div>
                    <div>
                        <input
                                type="text"
                                style="
                        width:397px;
                        height:40px;
                        padding:0px 10px;
                        border:1px solid #979797;
                        border-radius:4px;
                    "
                                placeholder="请输入"
                                v-model="newCameraInfo.externalTcpPort"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">外部RTSP端口</div>
                    <div>
                        <input
                                type="text"
                                style="
                        width:397px;
                        height:40px;
                        padding:0px 10px;
                        border:1px solid #979797;
                        border-radius:4px;
                    "
                                placeholder="请输入"
                                v-model="newCameraInfo.externalRtspPort"
                        />
                    </div>
                </div>
                <div class="dialog-form-item">
                    <div class="dialog-form-item-label" style="vertical-align: top">监控编号</div>
                    <div>
                        <input
                                type="text"
                                style="
                            width:397px;
                            height:40px;
                            padding:0px 10px;
                            border:1px solid #979797;
                            border-radius:4px;
                        "
                                placeholder="请输入"
                                maxlength="20"
                                v-model="newCameraInfo.name"
                        />
                    </div>
                </div>

                <div class="dialog-form-item">
                    <div class="dialog-form-item-label" style="vertical-align: top">监控位置</div>
                    <div style="width:400px">
                        <ChooseLocation @changeLocation="changeLocation"
                                        :defaultLocationName="newCameraInfo.location"/>
                    </div>
                </div>


                <div class="dialog-form-item">
                    <div class="dialog-form-item-label">识别方案</div>
                    <div>
                        <CSSelect iWidth="36px" height="40px">
                            <select v-model="newCameraInfo.detectionType" style="width:385px;color: #999999"
                            >
                                <option
                                        v-for="camera in recognitionType"
                                        :key="camera.boxId"
                                        :value="camera.boxId"
                                >
                                    &nbsp;{{camera.count != null?camera.boxName +'(已关联'+camera.count +'个监控)':camera.boxName}}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
            </div>
        </template>
    </CSDialog>
</template>

<script>
    import {querySimilarLocationUrl, addCameraUrl, queryAiBoxList} from "@/requestUrl"
    import ChooseMeterLocation from "@/components/ChooseMeterLocation";
    import CSDialog from "@/components/common/CSDialog";
    import CSSelect from "@/components/common/CSSelect";
    import CSRadio from "@/components/common/CSRadio";
    import ChooseLocation from "@/components/ChooseLocation";

    export default {
        name: 'addCamera',
        components: {
            CSRadio,
            CSDialog,
            CSSelect,
          ChooseMeterLocation,
            ChooseLocation
        },
        data() {
            return {
                LocationList: [],
                cameraEventType: {
                    1: '人脸检测',
                    2: '移动侦测'
                },
                addCameraVisible: false,
                newCameraInfo: {
                    brand: "",
                    type: "",
                    sn: "",
                    user: "",
                    password: "",
                    privateIp: "",
                    eventType: '', //事件类型1.人脸检测2.移动侦测
                    externalTcpPort: '',
                    externalRtspPort: '',
                    publicIp: "",
                    name: "",
                    location: "",
                    locationId: "",
                    detectionType: ""
                },
                cameraModelList: [
                    {id: 1, name: "大华"},
                    {id: 2, name: "海康威视"},
                ],
                recognitionType: []
            }
        },
        created() {
            this.$vc.on(this.$route.path, "cameraManage", "addCamera", (data) => {
                this.addCameraVisible = data;
                this.newCameraInfo = {
                    brand: "",
                    type: "",
                    sn: "",
                    eventType: '', //事件类型1.人脸检测2.移动侦测
                    user: "",
                    password: "",
                    privateIp: "",
                    externalTcpPort: '',
                    externalRtspPort: '',
                    publicIp: "",
                    name: "",
                    location: "",
                    locationId: "",
                };
            })
            this.getLocationList()
            this.queryBox()
        },
        methods: {
            //获取位置和位置ID
            changeLocation(location) {
                this.newCameraInfo.locationId = location.id;
                let buildingName = `${location.buildingName}`
                let floor = `${location.floor}`
                let locationInfo = '';
                if (!(buildingName === '') && !(buildingName === 'undefined' && !(buildingName === undefined))) {
                    locationInfo += buildingName;
                }
                if (!(floor === '') && !(floor === undefined) && !(floor === 'undefined')) {
                    locationInfo += floor + '层';
                }
                locationInfo += `${location.specificLocation}`;
                this.newCameraInfo.location = locationInfo;
                console.log(this.newCameraInfo.location);
            },
            getLocationList() {
                this.$fly.post(
                    querySimilarLocationUrl,
                    {
                        regionCode: this.$vc.getCurrentRegion().code,
                        specificLocation: ""
                    }
                )
                    .then((res) => {
                        this.LocationList = res.data
                    })
            },
            addCamera() {
                if (this.judgement()) {

                    this.$fly.post(addCameraUrl, this.newCameraInfo)
                        .then(res => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.addCameraVisible = false;
                            this.$vc.emit(this.$route.path, 'cameraManage', 'refresh', 0)
                            this.$vc.toast('添加摄像头成功');
                            this.queryBox();
                        })
                }
            },
            judgement() {
                return this.$vc.validate.validate(
                    {
                        newCameraInfo: this.newCameraInfo,
                    },
                    {
                        "newCameraInfo.brand": [
                            {
                                limit: "required",
                                param: "",
                                errInfo: "请选择第三方监控",
                            }
                        ],
                        "newCameraInfo.type": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入监控型号",
                            },
                        ],
                        "newCameraInfo.sn": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入序列号"
                            }
                        ],
                        "newCameraInfo.user": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入用户名"
                            }
                        ],
                        "newCameraInfo.password": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入密码"
                            }
                        ],
                        "newCameraInfo.privateIp": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入内网IP",
                            }
                        ],
                        "newCameraInfo.publicIp": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入外网IP",
                            }
                        ],
                        "newCameraInfo.name": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入监控编号",
                            }
                        ],
                        "newCameraInfo.location": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请选择监控位置",
                            }
                        ],
                        "newCameraInfo.detectionType": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请选择识别方案",
                            }
                        ]
                    }
                )
            },
            queryBox() {
                this.$fly.get(queryAiBoxList, {
                    regionCode: this.$vc.getCurrentRegion().code,
                }).then(res => {
                    if (res.code !== 0) {
                        return;
                    } else {
                        this.recognitionType = [{boxName: "请选择"},
                            {boxId: 0, boxName: "虹软"}].concat(res.data);
                    }
                })
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .tenant-detail-dialog
        .el-dialog__header
            display none

        .preview-tenant
            color #000
            padding 30px
            font-size 24px
            overflow-y auto

            .field
                &-label
                    width 170px
                    display inline-block
                    margin-right 40px
                    text-align right
                    vertical-align top

                &-content
                    display inline-block
                    max-width calc(100% - 240px)

        .el-divider__text.is-left
            font-size 24px

    .dialog-form
        padding 27px 30px
        font-size 24px
        color #000

        &-item
            &:not(:last-of-type)
                margin-bottom 23px

            &-label
                width 156px
                height 33px
                text-align right
                margin-right 40px
                display inline-block
                vertical-align middle
                line-height 33px

            & > div
                display inline-block
                vertical-align middle

                input
                    &::placeholder
                        color #999
                    width 140px
                    height 40px
                    border-radius 4px
                    padding 0 5px
                    border 1px solid #979797
                    vertical-align middle
                    padding-left 10px


</style>
